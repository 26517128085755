import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  isLoading = false;

  constructor(public loadingController: LoadingController) { }

  async present(m) {
 if(!m)return;

    this.isLoading = true;
    return await this.loadingController.create({
      message: m,
      duration: 60000,
      spinner: 'bubbles',
    }).then(a => {
      a.present().then(() => {
      //  console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }


  
  async dismiss() {
  if(this.isLoading === false) return;

    this.isLoading = false;
    return await this.loadingController.dismiss();
  }


}