import { Injectable} from '@angular/core';
import { Router,RoutesRecognized} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AlertController, NavController, ToastController} from '@ionic/angular';
import { ApiLinkService } from '../../_service/api-link/api-link.service';
import { isPlatform} from '@ionic/angular';

import { LoadingService } from '../loading/loading.service';


@Injectable({
  providedIn: 'root'
})
export class AppserviceService {
mainlogo: string = ""
  
company: string = "PORTAL";
companyprefix: string = "";
clogo: string = ""
/*=========================================================
DATE MAY ONLY BE STORED / SENT USING - and not /

/ ONLY SERVE VISUAL EASE

/ MAY NOT BE USED IN SERVER SIDE SEARCH FIELDS

/ ARE OTHERWISE RECODED FOR TRANFER TO SERVER.
=========================================================*/

//PLATFORM
platWidth: any = 0;
platHeight: any = 0;
isIOS: boolean;
screenFull: boolean = false;
//screen: any;


//APP FUNCTIONS
timeOutVal: number = 1000;
alertRes: number = 0;
errMsg: any = null;
msg: any = null;
dblButton: boolean = false;
hiddenButton: boolean = false;
bkButtonHide: boolean = false;
curCt: number = 0;
keyCt: number = 0;
appTimer: number;

//LOGGED IN
credsInfo: any = null;
isLoggedIn: any = null;


//Connection Timer
dataVal: any;

//NAV
prevUrl: any = null; 
urlstate: any = "/";
pgTitle: any;


//SCROLL
topSectionHidden: boolean = false; // l
scrollpages: string = "loads reports";
scrollLoads: boolean = false;
scrollReports: boolean = false;


//REPORTS
loadsReportInfo: any=[];
loadsReportInfoCT: any;
curInv: number;


srchInput: any = "";
chkSearch: boolean = true;
frDate: string;
toDate: string;
selectedClient: string = '';
 
loadInv: any;
data: any;
isReportInfoSum: any;
isReportInfoSetl: any;
isReportInfoDiff: any;
isReportInfoPend: any;
loadsReportInfoTOP: string;
loadsReportInfoBTM: string;
isReoprtInfoMiles: any;
isReoprtInfoDho: any;
isReoprtInfoRPM: any;
isReoprtInfoAVG: any;
isReoprtInfoAM: any;
isReoprtInfoADH: any;
initInvoice: any;

isMiniWidth: boolean;
screenInfo: any;

phrase: string;
profile: string = "puser";
sqlFr: any;
sqlTo: any;


genDate: any;

clientsPick: any=[];
needClients: boolean = true;

//DATA
viewData: any=[];
viewClients: any=[];
justsaved: boolean = false;

firstrun: boolean = true;
rangeStart: boolean = true;
rangeEnd: boolean = true;  //possibly false if can catch last maxInv Date

startDate: string;
states: any = [];


//this service frees up app component at load tiem
constructor(public router: Router, public loading: LoadingService, public navCrtl: NavController, 
private alertController: AlertController,private toastCtrl: ToastController, 
public apiLink: ApiLinkService) {

if(isPlatform('ios')) this.isIOS = true;
}


async clearSrch(){
this.srchInput = ''; 
}




//RUNS WITHOUT DATE CRITERIA
async clientList(){
return await new Promise(async resolve => { 

this.connTimer('Refreshing....',null,true);  
  let idSub: any = this.apiLink.getAllLoads(this.company).subscribe(async a => { 
  this.dataVal = 'Stop ConnTimer'; 
  
    if(a.includes("not found")){
      this.msg = "no client list found";
      this.showToast('bottom','danger');


      } 
      else {
        //reCode & sort descending
          await this.postResults(a).then(()=>{
          this.clientsPick = this.cListSetup(a);
          this.needClients = false;      
          });
        }
      idSub.unsubscribe();  
      }, err=>{
      console.log(err);
      });

  console.log("FULL CLIENT LIST");
  resolve(true);
  });
}




async viewDataSet(){

let x: boolean = await new Promise(async resolve => { 
this.viewData = [];
this.viewClients = []; 
resolve(true);
});

if(!x){
alert("data didn't clear")
return;  
} 

this.loadsReportInfoCT = null;
this.isReportInfoSum = null;
this.isReportInfoSetl = null;
this.isReportInfoDiff = null;
this.isReportInfoPend = null;
this.isReoprtInfoMiles = null;
this.isReoprtInfoDho = null;
this.isReoprtInfoAVG = null; 
this.isReoprtInfoAM = null; 
this.isReoprtInfoADH = null; 
this.isReoprtInfoRPM = null;  
this.loadsReportInfoTOP = null;    
this.loadsReportInfoBTM = null;

let fullset = this.loadsReportInfo;  //FROM SERVER ALL OR DATE SEARCH
let sum: number = 0;
let setl: number = 0;
let miles: number = 0;
let dh: number = 0;
let ct: number = 0;
let stotal: number = 0;


fullset.filter((inv: any)=>{

    if(inv.brokername === this.srchInput.trim() || this.srchInput.trim().length === 0){

    let t = Number(inv.totals.replace(/[^\d.-]+/gi, ""));
    let s = Number(inv.settledamt.replace(/[^\d.-]+/gi, ""));


     if(!inv.loadpo.trim().includes('LEDGER')){
      sum = sum + t;

      //total settled amt
      setl = setl + s;
      //total revenue invoice if settled | for fee calc.
      if(s > 0) stotal = stotal + t

      miles = miles + Number(inv.milesbill.replace(/[^\d.]+/gi, "")); 
      dh = dh + Number(inv.milesto.replace(/[^\d.]+/gi, ""));
      ct = ct + 1;
     } 

     else {
     // console.log(t); 
      setl = setl + t;
     }


 
      this.viewData.push(inv);      
    }
})
//console.log("VIEW DATASET + DATASET CLIENTS"); // this.viewData[0]);
//CONVERT FLIP FIRST SERVER SEARVH RANGE  
let t =this.flipSqlDate2Local(fullset[fullset.length-1].idate)
let b =this.flipSqlDate2Local(fullset[0].idate)
this.loadsReportInfoTOP = t;   
this.loadsReportInfoBTM = b;

console.log('----->>' + t,b);


//VIEW CLIENTS IN SERVER DATASET
this.viewClients = this.cListSetup(fullset);


//MAKE VIEW DATASET
this.loadsReportInfoCT = ct;
this.isReportInfoSum = this.str2moneyN(sum,null,"C"); 
this.isReportInfoSetl = this.str2moneyN(setl,null,"C"); 
this.isReportInfoDiff = this.str2moneyN(stotal - setl,null,"C");
this.isReportInfoPend = this.str2moneyN(sum - stotal,null,"C"); 
this.isReoprtInfoMiles = this.str2moneyN(miles,null,"S"); 
this.isReoprtInfoDho = this.str2moneyN(dh,null,"S"); 

let p = sum / miles; 
let a = sum / ct;
let m = miles / ct;
let d = dh / ct;

this.isReoprtInfoRPM = this.str2moneyN(p,null,"C");
this.isReoprtInfoAVG = this.str2moneyN(a,null,"C");
this.isReoprtInfoAM = this.str2moneyN(m,null,"S");
this.isReoprtInfoADH = this.str2moneyN(d,null,"S");

this.isNextMonth();
}





async viewLoads() {
return await new Promise(async resolve => { 

this.loadsReportInfo = [];
let searchValT = this.srchInput.trim();

if(this.chkSearch){
this.msg = null;
this.sqlReadyDate(this.frDate.trim(),"from"); //2022-05-15
this.sqlReadyDate(this.toDate.trim(),"to");

  if(this.sqlFr && this.sqlTo.length === 0 || this.sqlTo && this.sqlFr.length === 0){
  this.msg = 'Both dates required';
  this.showToast('bottom','primary');
  return;  
  } 

  if(this.sqlFr > this.sqlTo){
  this.msg = 'Dates <> mismatch';
  this.showToast('bottom','primary');
  return;  
  }

  if(this.sqlFr.length === 0 && this.sqlTo.length === 0 
  && searchValT.length === 0){
  this.msg = 'Enter a search value / dates';
  this.showToast('bottom','primary');
  return;  
  } 

let s: any = {
  "val": "", // do notsearch server for names
  "from": this.sqlFr,
  "to": this.sqlTo   
}

this.connTimer('Searching....',null,true);  
let idSub: any = this.apiLink.getLoadsBy(s, this.company).subscribe(async a => {
this.dataVal = 'Stop ConnTimer';  

if(a.includes("not found")){
  this.viewData = [];
  this.viewClients = []; 
  this.loadsReportInfoCT = null;
  this.isReportInfoSum = null;
  this.isReportInfoSetl = null;
  this.isReportInfoDiff = null;
  this.isReportInfoPend = null;
  this.isReoprtInfoMiles = null;
  this.isReoprtInfoDho = null;
  this.isReoprtInfoAVG = null; 
  this.isReoprtInfoAM = null;   
  this.isReoprtInfoADH = null;  
  this.isReoprtInfoRPM = null;  
  this.loadsReportInfoTOP = null;    
  this.loadsReportInfoBTM = null;

  this.msg = searchValT + " not found in view";
  this.showToast('bottom','danger');

  } 
  else {
    //reCode & sort descending
    await this.postResults(a).then(()=>{
    this.loadsReportInfo = this.alignData(a); 
    //console.log("SERVER =====> REFRESH DATA BY CRITERIA");
    resolve(true);
    })
    
  }
  idSub.unsubscribe();  
  }, err=>{
  console.log(err);
  });
  
} 
else{

//ignores search criteria
this.connTimer('Refreshing....',null,true);  
  let idSub: any = this.apiLink.getAllLoads(this.company).subscribe(async a => {
  this.dataVal = 'Stop ConnTimer'; 
  
  if(a.includes("not found")){
    this.msg = "no loads found";
    this.showToast('bottom','danger');

    this.viewData = [];
    this.viewClients = []; 
    this.loadsReportInfoCT = null;
    this.isReportInfoSum = null;
    this.isReportInfoSetl = null;
    this.isReportInfoDiff = null;
    this.isReportInfoPend = null;
    this.isReoprtInfoMiles = null;
    this.isReoprtInfoDho = null;
    this.isReoprtInfoAVG = null; 
    this.isReoprtInfoAM = null;  
    this.isReoprtInfoADH = null;   
    this.isReoprtInfoRPM = null;  
    this.loadsReportInfoTOP = null;    
    this.loadsReportInfoBTM = null;
    } 
    else {
      //reCode & sort descending
   await this.postResults(a).then(()=>{
    this.loadsReportInfo = this.alignData(a); 
    this.firstrun = false;
    
  
    console.log("SERVER =====> REFRESH DATA ALL");   
    resolve(true); 
    })
    } 
    idSub.unsubscribe();  
    }, err=>{
    console.log(err);
    });


  } 

});

}






cListSetup(a){
let res: any =[];
let lst: string = '';

//SYSTEM MUST NOT ALLOW EXTRACTABLE NAMES
a.forEach((c: any) =>{
  if(c.loadpo.trim().includes('LEDGER') && this.profile !== 'padmin') return;

if(!lst.includes(c.brokername)){
  lst = lst + c.brokername;     
  let i ={brokername: c.brokername,
    brokerdata1: c.brokerdata1,
    brokerdata2: c.brokerdata2,
    servicedata3: c.servicedata3,
  };
  res.push(i);
  }
})  
return res;
}





alignData(a){



a.forEach((inv: any) => { 


    //insert  
   if(inv.rate !== "$0.00" && inv.milesbill !== "0"  ){
   let r: number = this.str2moneyN(inv.rate,null,"N") /this.str2moneyN(inv.milesbill,null,"N");          
   inv.rpm = this.str2moneyN(r,null,"C");              
   }

 

   let o = inv.servicedata1;
   if(o && o.includes(",")){
   inv.org = o.slice(o.lastIndexOf( "," )+1).trim();  

   } else inv.org = "XX";
 


   //keep only numbers and commas  
   let d = inv.servicedata2;
   if(d && d.includes(",")){
   inv.dest = d.slice(d.lastIndexOf( "," )+1).trim();  
   } else inv.dest = "XX";

 
   let t = inv.servicedata3;
   if(t.trim()){

   let rm = t.slice(t.indexOf("Date:")+5,  t.indexOf("Driver:") -t.length).trim();
   if(rm){
   inv.dstatus = rm;  
   } else inv.dstatus = "1";

   }




   inv.rate = this.str2moneyN(inv.rate,null,"C");
   inv.othercharges = this.str2moneyN(inv.othercharges,null,"C");
   inv.settledamt = this.str2moneyN(inv.settledamt,null,"C");

   
});


return a;
}




async postResults(a: any){
//RUNS FASTER INSIDE
  Object.keys(a).forEach(i => {

    Object.keys(a[i]).forEach(key => {

      if( a[i][key] === null ){      
        a[i][key] = "";
      } 
      
      if(!this.srchWords(key, ["modified"])){
  
      let rn = /b@@rn/gi; // \r\n
      let fs = /b@@fsl/gi; // 5/26/2022 etc
      let bs = /b@@bsl/gi; // for \ back slash (prohibit)
      let ht = /b@@htg/gi; // for \# (prohibit)
      let pc = /b@@pct/gi; // for \ % (prohibit)
      let qm = /b@@qm/gi; // ?
      let bl = /b@@brl/gi; // [ (prohibit)
      let br = /b@@brr/gi; // ](prohibit)
        
      a[i][key] = a[i][key]
      .replace(rn,"\r\n")
      .replace(fs,"/")
      .replace(bs,"\\")
      .replace(ht,"#")
      .replace(pc,"%")
      .replace(qm,"?")
      .replace(bl,"[")
      .replace(br,"]");  
    //  console.log(key, a[i][key]);
      }
    });
  });  



 

  

  //SORT HIGHEST DAILY NUMBER
    a.sort(function(a: { loadinvoice: string; }, b: { loadinvoice: string; }){
    let aa = a.loadinvoice.split('-').pop();
    let bb = b.loadinvoice.split('-').pop();

    if(aa < bb) return 1;  //DESC ORDER
    if(aa > bb) return -1; 
    return 0;
    });

      
    //THEN BY LAST DATE
     let ln = this.companyprefix.length;
     a.sort(function(a: { loadinvoice: string; }, b: { loadinvoice: string;}){
      let aa = a.loadinvoice.substring(ln, a.loadinvoice.indexOf("-"));
      let bb = b.loadinvoice.substring(ln, a.loadinvoice.indexOf("-"));

    if(aa < bb) return 1;  //DESC ORDER
    if(aa > bb) return -1; 
    return 0;
    });   



  
  //SORT HIGHEST YR
  a.sort(function(a: { loadinvoice: string; }, b: { loadinvoice: string;}){

  let ax = a.loadinvoice.substring(0,a.loadinvoice.indexOf("-"));
  let bx = b.loadinvoice.substring(0,b.loadinvoice.indexOf("-"));   

  let aa = ax.substring(ax.length-3);  //023 etc
  let bb = bx.substring(bx.length-3);

    if(aa < bb) return 1;  //DESC ORDER
    if(aa > bb) return -1; 
    return 0;
    });      
} 




//6-5-2022
sqlReadyDate(a: string, q: string){

  let m = a.substring(0, a.indexOf('-'));  //month  6
  let d = a.slice(m.length + 1, (a.length - 5)); //day   11    slice off 2 out of 4
  let y = a.slice(-4); //year 2022
  if(m.length === 1) m = '0' + m; 
  if(d.length === 1) d = '0' + d;
  let sd = y + '-' + m + '-' + d;

if(q === 'from'){
  this.sqlFr = sd;
  return;
}

if(q === 'to'){
  this.sqlTo = sd;
  return;
}



if(q === 'general'){
  this.genDate = sd;
  return;
}


console.log(sd);  // 2022-05-15
return sd;
}



//2022-05-15
flipSqlDate2Local(a: string){
  let y = a.slice(0, 4); //year 2022
  let m = a.slice(5, -3);  //month  05
  let d = a.slice(-2); //day   15

if(m[0] === "0") m = m.slice(-1); 
if(d[0] === "0") d = d.slice(-1);
let sd = m + '-' + d + '-' + y;
//console.log(sd, " flip SQL dates to Local Date")
return sd; //05-15-2022
}





str2moneyN(n,a,q){
if(typeof n !== 'number') n = Number(n.replace(/[^\d.-]+/gi, ""));
let c: string = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(n);
let t: string;

if(a){
if(typeof a !== 'number') a = Number(a.replace(/[^\d.-]+/gi, ""));
t = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(a + n);
}

let s: string = c.slice(1, -3); // remove $
if(q === 'N') return n; //numeric
if(q === 'C') return c; //currency
if(q === 'T') return t;  //total
if(q === 'S') return s; //string number
if(q === 'A') return  { n: n, c: c, t: t, s: s};
}
  





async backRange(){
this.rangeEnd = true;    
let sf = new Date(this.startDate); 
sf = new Date(sf.getFullYear(), sf.getMonth() + 1, 1); //fisrt of this month

let cf = new Date();
cf = new Date(cf.getFullYear(), cf.getMonth() + 1, 1); //fisrt of this month
let d = new Date(this.frDate.replace(/[-]+/gi, "/"));
this.frDate = new Date(d.getFullYear(), d.getMonth() - 1, 1).toLocaleDateString().replace(/[/]+/gi, "-");
this.toDate = new Date(d.getFullYear(), d.getMonth(), 0).toLocaleDateString().replace(/[/]+/gi, "-");


if(d.getFullYear()  + " " +  d.getMonth() === sf.getFullYear()  + " " + sf.getMonth()){ 
  this.rangeStart = false;
  }

console.log(this.frDate, this.toDate, this.rangeStart );
this.selectedClient = ""; 
await this.viewLoads().then(a=>{
if(a)this.viewDataSet();  
}); 
}







async fwdRange(){
this.rangeStart = true;
let cf = new Date();
cf = new Date(cf.getFullYear(), cf.getMonth() - 1, 1); //fisrt of prev month

let d = new Date(this.frDate.replace(/[-]+/gi, "/"));
let f1 = new Date(d.getFullYear(), d.getMonth() + 1, 1).toLocaleDateString().replace(/[/]+/gi, "-");
let t1 = new Date(d.getFullYear(), d.getMonth() + 2, 0).toLocaleDateString().replace(/[/]+/gi, "-");

this.frDate = f1;
this.toDate = t1;

console.log(this.frDate, this.toDate, this.rangeEnd);




  this.selectedClient = ""; 
  await this.viewLoads().then(a=>{
  if(a)this.viewDataSet();  
  }); 


}


isNextMonth(){
  let tmpD = new(Date);
  let isDay: any = tmpD.toISOString().slice(0, 10); //same as created SQl date function but shorter 
  let nxt: string;

  let cd = new Date(this.frDate.replace(/[-]+/gi, "/"));
  
  if (cd.getMonth() === 11) { //12 month
  nxt = new Date(cd.getFullYear() + 1, 0, 1).toLocaleDateString().replace(/[/]+/gi, "-");
  } else {
  nxt = new Date(cd.getFullYear(), cd.getMonth() + 1, 1).toLocaleDateString().replace(/[/]+/gi, "-");
  }


  this.sqlReadyDate(nxt,"general")
  console.log(this.genDate, " the next month")

  if(this.genDate > isDay){
  this.rangeEnd = false;
  } else this.rangeEnd = true;  
  }





async newInv(){
if(this.loadsReportInfo.length === 0){
alert('No data - please view last loads list and try again')
return;
}



return await new Promise(async resolve => {
this.curInv = -1;

let i: any = {
    "loadinvoice": "",
    "company": this.company,
    "address": "7509 Cleveland St",
    "address2": "Hollywood FL, 33024",
    "phone": "(347) TRUCK-IT",  
    "email": "pickdisup@gmail.com",
    "mcauth": "MC 1263854\r\nDOT 3658465",
    "loadpo": "",
    "idate": "", //IS STRING
    "brokername": "",
    "brokerdata1": "",
    "brokerdata2": "",
    "serviceorder": "",
    "rate": "$0.00",
    "othercharges": "$0.00",
    "milesbill": "",
    "servicedata1": "",
    "servicedata2": "",
    "servicedata3": "Date: 0\r\nDriver:",
    "receiptdata1": "TO FACTORING COMPANY:\r\n\r\nTRIUMPH BUSINESS CAPITOL\r\nC/O:\r\nPICKDISUP TRANSPORT, LLC\r\nP.O. BOX 610028\r\nDALLAS, TX 75261-0028",
    "receiptdata2": "CONTACT INFORMATION:\r\n\r\nwww.triumphbcap.com\r\nPH: (469) 312-7186\r\nEMAIL: rvasquez@tbcap.com",
    "loadwgt": "",
    "milesto": "0",
    "milesfrom": "0",
    "settleddate": "",
    "settledamt": "$0.00",
    "invoicenotes": "",
    "modified": false, 
    "editedby": "GS",
    "posted": "",
    "printed": "NO",    
    "totals": "$0.00"   //CLIENT SIDE ONLY !!!
  };

this.loadInv = i;

let a = new Date().toLocaleDateString().replace(/[/]+/gi, "-");
let ni
                                        //6-11-2022
let m = a.substring(0, a.indexOf('-'));  //month  6
let d = a.slice(m.length + 1, (a.length - 5)); //day   11    slice off 2 out of 4
let y = a.slice(-4); //year
//console.log(m,d,y);

if(m.length === 1) m = "0" + m;
if(d.length === 1) d = "0" + d;
ni = this.companyprefix + m + d + y.slice(-3);

/*
===========================================================
USE 2 DIGIT PREFIXES FOR NUMEROUS ACCOUNTS!!
=============================================================
*/

console.log(this.loadInv.idate = y + "-" + m  + "-" + d );


  this.msg = null;
  this.connTimer(null, null, false);
  
  let rSub: any = this.apiLink.chkInvoice(ni, this.company).subscribe(async loads => { 
  this.dataVal = 'Stop ConnTimer'; 
  //console.log(loads);
  
    if(loads.includes("not found")){
    this.loadInv.loadinvoice = ni + "-1";    
    resolve(true);
    } 
    else {
      let dscLoads = loads.sort(function(aa: { loadinvoice: string; }, bb: { loadinvoice: string; },){
      let an: number = Number(aa.loadinvoice.split('-').pop());
      let bn: number = Number(bb.loadinvoice.split('-').pop());
  
      if(an < bn) return 1;  //DESC ORDER
      if(an > bn) return -1; 
      return 0;
      });
  
      await dscLoads.filter((inv: any, index: number) => { 
      let nn: number = Number(inv.loadinvoice.split('-').pop());  
      if(index === 0) this.loadInv.loadinvoice = ni + '-' + (nn + 1);
      });      
  
      resolve(true);
      }  
      rSub.unsubscribe;      
    }, (err: any)=>{
      console.log(err);
    })
  });

  
  }





async onScroll(event: { detail: { deltaY: number; }; }) {
// used a couple of "guards" to prevent unnecessary assignments if scrolling in a direction and the var is set already:
//console.log(event.detail.deltaY);
if (event.detail.deltaY > 0 && this.topSectionHidden) return;
if (event.detail.deltaY < 0 && !this.topSectionHidden) return;

if (event.detail.deltaY > 10) {
await new Promise(resolve => setTimeout(resolve,300)); //smooth sliding 200 is most consistent
// console.log("scrolling down, hiding HEADER... ");  
this.topSectionHidden = true;
} 
else if (event.detail.deltaY < 0) { // IOS NEEDS 0
await new Promise(resolve => setTimeout(resolve,300));    
// console.log("scrolling up, revealing HEADER... ");
this.topSectionHidden = false;
};

if(this.router.url.includes('home')){
this.scrollLoads = this.topSectionHidden;
} else this.scrollReports = this.topSectionHidden;

//console.log(this.router.url + " hiddenHeader ? " + this.topSectionHidden);
};



async connTimer(m: string,t: number,s: boolean){
m = null;


this.dataVal = null;
this.msg = null;
this.errMsg = null;

if(!t) t = this.timeOutVal;
  if(s){
  if(m) this.loading.present(m);  
  this.dblButton = true;
  this.hiddenButton = true; 
  } 
let timer = t;
let ct = 4;
for(let i=0; i < ct; i++){ 
await new Promise(resolve => setTimeout(resolve, timer * (i+1) )); 
if(!this.dataVal){
  console.log(i+1 , ' Connection timer');   
if(i === 1) this.msg = m;  
if(i === 2) this.msg = 'Still trying to connect...';
if(i === 3) {

  if(m === "Updating"){
    this.msg = 'NOT SAVED - TRY AGAIN IN 20 SECONDS';
  } else this.msg = 'Connection error!';
  
  this.errMsg = 1; // Allow API message as not leaving page 
      if(s){
      if(this.loading) this.loading.dismiss();   
      this.dblButton = false;     
      this.hiddenButton = false; 
      }  
  }
if(s && i > 1) this.showToast('bottom','danger');  
}
else{     
      if(s){
        if(this.msg){
          if(!this.srchWords(this.msg, ["not found", "denied", "secret pin"]))  this.msg = null; // clear connection faults
        }  
        this.errMsg = null; // clear connection faults
        if(m) this.loading.dismiss();   
        this.dblButton = false;     
        this.hiddenButton = false; 
        }  
    return;  
    }      
  }  
}




srchWords(text: any, searchWords: any[]){
let multiSearchOr =
    searchWords.some((el) => {
      return text.match(new RegExp(el,"i"))
    });
return multiSearchOr;
}


  
async showToast(position: string, c: string) {
let toast = this.toastCtrl.create({
message: this.msg,
duration: 800,
color: c,
})
;(await toast).present();
//console.log(this.msg);
}




public getprevUrl(){
let qUrl = this.router.events
.pipe(filter((e: any) => e instanceof RoutesRecognized),
pairwise()
).subscribe((e: any) => {
this.prevUrl = e[0].urlAfterRedirects;
qUrl.unsubscribe();
}); 
if(!this.prevUrl)  {
console.log('No previous Url >>> App Svc >>>');
}else{
console.log(this.prevUrl + ' App Svc >>>');
return this.prevUrl
}
}


public async appConfirm(){
  const alert = await this.alertController.create({
    //header: 'Confirm!',
    backdropDismiss: false,
    message: '<strong>Are you sure?</strong>',
    buttons: [
        {
        text: 'Yes',
        handler: (site) => {
        console.log('Yes'); 
        this.alertRes = 1;
       // return true
           }
          },
            {
            text: 'No',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
            console.log('No');
            this.alertRes = 0
            }
        }]
        });
        await alert.present();
        } //alert end  

          
                            
// app functions    
public scrollToElem(ele,pos){
var page = document.getElementById(ele);
//console.log(page);
  if (page !== null){
  page.scrollIntoView(pos); 
  }  
}




goPage(pg: any, aUrl: string) {
this.pgTitle = pg;
this.router.navigate([aUrl],{ skipLocationChange: true  });
}



} 

