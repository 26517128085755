import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AppserviceService } from "../_service/appservice/appservice.service";


@Injectable({
providedIn: "root"
})
export class CanActivateGuard implements CanActivate {


permit: any = null;  // must be null.... until set also triggered by menu
goToUrl: string;
isLogout: number = 0;



constructor(public router: Router, public appService: AppserviceService) {
    
}


navStats(){
console.log("ROUTE STATS" + this.router.url + ' <> '+ this.goToUrl );

}




// PROMISE
async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
 

this.goToUrl = '/'+ route.parent.routeConfig.path;
this.navStats();




if(this.goToUrl === "/" && this.router.url === "/"){    
return true; 
} 


//1.
if(this.goToUrl === "/splash" && (this.router.url === "/" || this.appService.srchWords(this.router.url,["home"]))){    
return true; 
} 



if(this.goToUrl === "/home" && this.appService.srchWords(this.router.url,["splash","invoice","trips"])){ 
this.permit = 0;       
return true; 
} 


if(this.goToUrl === "/invoice" && this.appService.srchWords(this.router.url,["splash","home"])){    
return true; 
} 



//3. BOUNCE BACK
this.router.navigate([this.router.url],{skipLocationChange: true});  
history.pushState (null, null,""); 
history.pushState (null, null,""); 
console.log("reject can activate");
return false;



}  // end observable function



} // end of class