import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class ApiLinkService {
//public API = 'https://srvlegacy.com:8993';


//public API = 'https://198.38.94.14:8993';
public API = 'https://pepolapi.com';


constructor(
public http: HttpClient) {
}


apiTest(): Observable<any> {
  
this.http.get(this.API + '/api/tap/').subscribe(a =>{
  console.log(a);
})
  
  return 
}



getAllLoads(c): Observable<any> {
return this.http.get(this.API + '/loads/' + c);
}


getLastLoads(c): Observable<any> {
return this.http.get(this.API + '/lastloads/' + c);
}



getLoadsBy(x,c): Observable<any> {
  let data: string
  data = JSON.stringify(x)
  .replace(/\\r\\n|\\r|\\n/gi, 'b@@rn') // = \r\n
  .replace(/\//gi, 'b@@fsl') // = /
  .replace(/\\/gi, 'b@@bsl') // = \
  .replace(/\#/gi, 'b@@htg') // = \
  .replace(/\%/gi, 'b@@pct') // = \
  .replace(/\?/gi, 'b@@qm') // = \
  .replace(/\[/gi, 'b@@brl') // = \
  .replace(/\]/gi, 'b@@brr'); // = \

return this.http.get(this.API + '/loadsby/' + data + '/' + c);
}


saveInvoice(a: any, ): Observable<any> {
  
let data: string
//console.log(
  data = JSON.stringify(a)
.replace(/\\r\\n|\\r|\\n/gi, 'b@@rn') // = \r\n
.replace(/\//gi, 'b@@fsl') // = /
.replace(/\\/gi, 'b@@bsl') // = \
.replace(/\#/gi, 'b@@htg') // = \
.replace(/\%/gi, 'b@@pct') // = \
.replace(/\?/gi, 'b@@qm') // = \
.replace(/\[/gi, 'b@@brl') // = \
.replace(/\]/gi, 'b@@brr'); // = \

return this.http.get(this.API + '/loadsave/' + data);
}



//FOR NEW INVOICE NUMBER
chkInvoice(a: string, c: string ): Observable<any> {
console.log(a);
return this.http.get(this.API + '/chkloadinv/' + a + '/' + c);
}











}
