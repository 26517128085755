import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppserviceService } from '../_service/appservice/appservice.service';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
permit: number = 0; 
isLogout: number = 0;
isEnroll: number = 0;
cartLock: boolean = false;
goToUrl: string;

constructor(public router: Router, public appService: AppserviceService) {
  }




navStats(){
console.log("ROUTE STATS" + this.router.url + ' <> '+ this.goToUrl + ' permit? ' + this.permit);
}

canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot,state: RouterStateSnapshot) {

this.goToUrl = '/'+ route.parent.routeConfig.path;  
this.navStats();





this.router.navigate([this.router.url],{ skipLocationChange: true});  
history.pushState (null, null,""); 
history.pushState (null, null,""); 
console.log("reject deactivate");
this.permit = 0;
return false; 

} // end observable


} // end class