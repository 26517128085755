import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CanActivateGuard } from './_gaurd/canAc-app-guard.service';
import { CanDeactivateGuard } from './_gaurd/canDe-app-guard.service';

const routes: Routes = [
{
path: 'splash',
loadChildren: () => import('./splash/splash.module').then( m => m.SplashPageModule)
},
{
path: 'invoice',
loadChildren: () => import('./invoice/invoice.module').then( m => m.InvoicePageModule)
},
{
path: 'home',
loadChildren: () => import('./home/home.module').then( m => m.homePageModule)
},
{
path: 'trips',
loadChildren: () => import('./trips/trips.module').then( m => m.TripsPageModule)
},
{
path: '',
loadChildren: () => import('./front/front.module').then( m => m.FrontPageModule)
},


{path: 'front', redirectTo: '', pathMatch: 'full'
}, // MUST ENTER SUB ACCT url-input-error

 
// MUST ENTER SUB ACCT 
{ path: '**', redirectTo: ''},
  
 
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  
  providers: [ 
    CanActivateGuard,
    CanDeactivateGuard
  ]
  
})
export class AppRoutingModule {}
