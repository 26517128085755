import { Component } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { NgZone } from '@angular/core';
import { App} from '@capacitor/app';
import { AppserviceService } from './_service/appservice/appservice.service';
import { Platform } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
name = 'Angular 5';   
appver: string = '0.002';

///  Idle // =====> fwd with masking // ===== temporary 302
idleState = null; //'Not started.'
timedOut = false;
min: any = null;
sec: any = null;
curmin: any;

iSub: any;
oSub: any;
toSub: any;
towSub: any;



constructor(public idle: Idle,private zone: NgZone,
public appService: AppserviceService,
private platform: Platform ) {

App.addListener('backButton', () => {
  // DO NOTHING this.exitAppC();
  //alert('EXIT?')
  });


this,this.initializeApp();  
}

async initializeApp() {
this.platform.ready().then(async () => {
console.log(this.appService.platWidth = this.platform.width());  

this.appService.platHeight = this.platform.height(); 
if(this.platform.width() < 320){
alert('screen not wide enough to display content \n 320px minimum');
}   

 
if(this.platform.width() < 330){
console.log(this.appService.isMiniWidth = true);
}   



  //this.statusBar.hide();
  //this.splashScreen.hide();  
  this.pgRefresh(); 

  
  //FIRST OF MONTH
  let tmpD = new Date();
  this.appService.frDate = new Date(tmpD.getFullYear(), tmpD.getMonth(), 1)
  .toLocaleDateString().replace(/[/]+/gi, "-");

  //LAST DAY OF MONTH
  this.appService.toDate = new Date(tmpD.getFullYear(), tmpD.getMonth() + 1, 0).toLocaleDateString().replace(/[/]+/gi, "-");

  


});


//this.appService.apiLink.apiTest();
}



pgRefresh(){
window.addEventListener("keyup", disableF5);
window.addEventListener("keydown", disableF5);
function disableF5(e) {
if ((e.which || e.keyCode) == 116) e.preventDefault(); 
};
}


//MUST REMAIN IN APP COMP TO INIT GUARDS
runAppTimeOut(){

let appTimeOut: number = 3600;
console.log('START TIMEOUT WATCH ' + appTimeOut); //seconds

this.idle.setIdle(appTimeOut);  
this.idle.setTimeout(appTimeOut/2);  //half the timeOut
this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

this.iSub = this.idle.onIdleEnd.subscribe(() => {
this.idleState = null;//. 'No longer idle'
this.zone.run(() => {
console.log('force update the screen');
});
});


this.toSub = this.idle.onTimeout.subscribe(() => {

this.idleState = 'Timed out!';
this.timedOut = true;

//UNSUBSCRIBE
this.iSub.unsubscribe();  
this.toSub.unsubscribe();
this.oSub.unsubscribe();
this.towSub.unsubscribe();
this.idle.stop();
console.log('TIMED OUT');

//this.deactGuard.isLogout = 1;
//this.canGuard.isLogout = 1;
//this.canGuard.permit = 1;


//this.router.navigate(['/welcome'],{ skipLocationChange: true });
//console.log('welcome from timeout');
window.location.reload();
//go to logout page after idle countdown.    
});



this.oSub = this.idle.onIdleStart.subscribe(() => this.idleState = 'Session idle!');

this.towSub = this.idle.onTimeoutWarning.subscribe((countdown) => {
let data = countdown/60;
this.min = data.toString().split('.')[0];
if(this.min > 0){
  this.curmin = this.min +' min ';  
} else{
  this.curmin = '';
}
this.sec = parseFloat(0+'.'+data.toString().split('.')[1])*60;
this.sec = (Math.round(this.sec * 100) / 100);
console.log(countdown)

this.idleState = 'Inactivity timeout ' + this.curmin  +this.sec+'  seconds';
});
this.reloadTimer();
}




reloadTimer() {
//alert('reloaded ' + this.router.url)  
this.idle.watch();
this.idleState = null;//'Started.'
this.timedOut = false;
}

}
